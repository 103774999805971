<template>
  <v-container>
    <DashboardHeader icon="mdi-cog" title="සැකසුම්" />

    <v-sheet class="custom-shadow px-3">
      <v-row>
        <v-col class="d-flex grow align-center">මුරපදය වෙනස් කරන්න</v-col>
        <v-col class="shrink">
          <v-btn @click="passwordForm = !passwordForm" color="accent" depressed>{{editButtonText}}</v-btn>
        </v-col>
      </v-row>
      <v-form @submit.prevent="changePassword" ref="form" v-if="passwordForm">
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="[requiredRule]"
              counter
              dense
              label="වත්මන් මුරපදය"
              type="password"
              v-model="formData.currentPassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="newPasswordRule"
              counter
              dense
              label="නව මුරපදය"
              type="password"
              v-model="formData.password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="passwordConfirmationRule"
              counter
              dense
              label="නව මුරපදය නැවත ටයිප් කරන්න"
              type="password"
              v-model="formData.passwordConfirmation"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-btn :loading="loading" block color="accent" depressed type="submit">සුරකින්න</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>

    <div class="d-flex align-start mt-8">
      <v-icon left>mdi-menu-right</v-icon>
      <p>
        ඔබගේ යෝජනාව වෙබ් අඩවියෙන් ඉවත්කර ගැනීමට අවශ්‍ය වූ විට 0717928627 හෝ 0717928628 අංකයට
        කාර්ය්‍යාල වෙලාවන්හිදී අමතා ඔබගේ අනන්‍යතාවය තහවුරු කරන්න.
      </p>
    </div>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_ALERT } from "../store/mutation-types";
import DashboardHeader from "../components/DashboardHeader";
import { ApiService } from '../services/api.service';

export default {
  components: {
    DashboardHeader
  },

  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    passwordForm: false,
    loading: false,
    formData: {
      currentPassword: '',
      password: '',
      passwordConfirmation: ''
    }
  }),

  computed: {
    editButtonText: function () {
      if (this.passwordForm) {
        return "වසන්න";
      } else {
        return "සංස්කරණය කරන්න";
      }
    },
    newPasswordRule: function () {
      const { currentPassword, password } = this.formData;
      return [
        v => !!v || 'අවශ්‍යයි',
        v => v.length >= 4 || 'අක්ෂර 4 ට වඩා වැඩි විය යුතුය',
        () => !(currentPassword && password && (currentPassword === password)) || 'නව මුරපදය ඔබගේ වර්තමාන මුරපදයට සමාන විය නොහැක. කරුණාකර වෙනත් මුරපදයක් ඇතුලත් කරන්න.'
      ];
    },
    passwordConfirmationRule: function () {
      const { password, passwordConfirmation } = this.formData;
      return [
        v => !!v || 'අවශ්‍යයි',
        () => !(password && passwordConfirmation && (password !== passwordConfirmation)) || 'තහවුරු කිරීමේ මුරපදය නොගැලපේ.'
      ];
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    changePassword: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const url = 'auth/change-password';
          const response = await ApiService.post(url, this.formData);
          this.showAlert({ message: 'මුරපදය සාර්ථකව වෙනස් කරන ලදි', type: "success" });
        } catch (error) {
          if (error.response.data.message == 'Your current password does not matches with the password you provided. Please try again.') {
            this.showAlert({ message: 'ඔබගේ වර්තමාන මුරපදය ඔබ ලබා දුන් මුරපදය සමඟ නොගැලපේ. කරුණාකර නැවත උත්සාහ කරන්න.', type: "error" });
          } else {
            this.showAlert({ message: 'මුරපදය වෙනස් කිරීම අසාර්ථකයි.', type: "error" });
          }
        }
        this.loading = false;
      }
    }
  }
}

</script>